import React, { useState } from "react";
import style from "./header.module.scss";
import pattern from "../../assets/patern.png";
import LanguageSelect from "../../UI/LanguageSelect/LanguageSelect";
import { IoMenu } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [showNav, setShowNav] = useState(false);
  const navigate = useNavigate();
  return (
    <div className={style.container}>
      <nav>
        <h1 style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          Antique Travel Expeditions
        </h1>
        <ul>
          <li onClick={() => navigate("/")}>{t("Home")}</li>
          <a href="#aboutus">
            <li>{t("AboutUs")}</li>
          </a>
          <a href="#tours">
            <li>{t("Tours")}</li>
          </a>
          <a
            target="_blank"
            href="https://www.booking.com/searchresults.html?checkout_year=&ss=tashkent&checkin_month=&checkin_year=&from_sf=1&aid=100994&sb=1&checkout_month=&b_h4u_keep_filters=&is_ski_area=0&src=theme_landing_index&sb_lp=1&sid=f19086fdf868f8b68bbf49632d8c2fb5&group_children=0&no_rooms=1&group_adults=2&src_elem=sb&error_url=https%3A%2F%2Fwww.booking.com%2Fhotel%2Findex.html%3Faid%3D100994%26sid%3Df19086fdf868f8b68bbf49632d8c2fb5%26"
          >
            {" "}
            <li>{t("Hotels")}</li>
          </a>
          {/* <li>{t("Transportation")}</li> */}
          <a onClick={() => navigate("/payment")}>
            {" "}
            <li>{t("payment")}</li>
          </a>
        </ul>
        <div className={style.navLeft}>
          <div onClick={() => setShowNav(!showNav)} className={style.menu}>
            <IoMenu />
          </div>
          <LanguageSelect />
        </div>

        {showNav && (
          <div className={style.mobileNav}>
            <ul>
              <li>{t("Home")}</li>
              <li>{t("AboutUs")}</li>
              <li>{t("Tours")}</li>
              <li>{t("Hotels")}</li>
              <li>{t("Transportation")}</li>
              <li>{t("payment")}</li>
            </ul>
          </div>
        )}
      </nav>
      <main>
        <img src={pattern} alt="" />
        <div>
          <h1>{t("HeadTitle")}</h1>
          <p>{t("HeadSlogan")}</p>
        </div>
      </main>
    </div>
  );
};

export default Header;
