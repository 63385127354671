import React from "react";
import style from "./tourCard.module.scss";
import CustomizedDialogs from "../../UI/Dialog/CustomizedDialogs";

const TourCard = ({ name, img, content }) => {
  return (
    <div className={style.container}>
      <p className={style.tour}>Tour</p>
      <img src={img} alt="" />
      <div className={style.overlay}>
        <p>{name}</p>
        {/* <button>Read More...</button> */}
        <CustomizedDialogs title={name} content={content} />
      </div>
    </div>
  );
};

export default TourCard;
