import React from "react";
import style from "./about.module.scss";
import camels from "../../assets/camels.png";
import { useTranslation, Trans } from "react-i18next";
const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <div id="aboutus" className={style.container}>
      <h1>{t("AboutUs")}</h1>
      <p>Antique Travel Expeditions</p>

      <div className={style.first_block}>
        <img
          src="https://antique-travel.com/static/media/culture2.e64edcf4dc7bea716bd2.jpg"
          alt=""
        />

        <p>
          <Trans t={t}>aboutus2</Trans>
        </p>
      </div>

      <div className={style.second_block}>
        <p>
          <Trans t={t}>aboutus3</Trans>
        </p>
        <img
          src="https://antique-travel.com/static/media/culture4.6eb83b27fe52f977b34d.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default About;
