import React from "react";
import style from "./footer.module.scss";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Facebook from "@mui/icons-material/Facebook";
const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.contactInfo}>
        <p>
          <span>
            <LocationOnIcon />
          </span>{" "}
          Bukhara city , Piridastgir Str 3/1, №25
        </p>
        <p>Uzbekistan 200100</p>
        <p>
          <span>
            <LocalPhoneIcon />{" "}
          </span>{" "}
          +99897 725-52-55
        </p>
        <p>
          <span>
            <EmailIcon />{" "}
          </span>{" "}
          info@antique-travel.com
        </p>
        <div className={style.socialLinks}>
          <a target="_blank" href="https://t.me/bekzodrj">
            <p>
              {" "}
              <span>
                <TelegramIcon />
              </span>{" "}
              Telegram
            </p>
          </a>
          <a target="_blank" href="https://wa.me/qr/YMS75A26V23ZG1">
            <p>
              {" "}
              <span>
                <WhatsAppIcon />
              </span>{" "}
              WhatsApp
            </p>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/profile.php?id=100091671776465"
          >
            <p>
              <span>
                <Facebook />
              </span>{" "}
              Facebook
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
