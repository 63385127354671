import React from "react";
import style from "./payment.module.scss";
import paymentImg from "../../images/payment.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const Payment = () => {
  return (
    <div id="payment" className={style.container}>
      <h1>Online Payment</h1>
      <p>Online payments built for success</p>
      <div className={style.payment}>
        <img src={paymentImg} alt="" />
        <div>
          <form>
            <TextField
              id="outlined-basic"
              label="Card Number"
              variant="outlined"
              className={style.third_input}
              placeholder="12345678123456"
            />
            <TextField
              id="outlined-basic"
              label="Expiration"
              variant="outlined"
              placeholder="12/19"
            />
            <TextField
              id="outlined-basic"
              label="CVV"
              variant="outlined"
              placeholder="CVV"
            />
          </form>
          <Stack direction="column" spacing={1}>
            <Button variant="contained">Humo</Button>
            <Button variant="contained">UzCard</Button>
            <Button variant="contained">MasterCard</Button>
            <Button variant="contained">Visa</Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Payment;
