import "./App.css";

import { Route, Routes } from "react-router-dom";
import CitiesGallery from "./pages/CitiesGallery";
import Main from "./pages/Main";
import GreenGallery from "./pages/GreenGallery";
import CultureGallery from "./pages/CultureGallery";
import ArtGallery from "./pages/ArchitechtureGallery";
import ArchitectureGallery from "./pages/ArchitechtureGallery";
import PeopleGallery from "./pages/PeopleGallery";
import FoodGallery from "./pages/FoodGallery";
import MetroGallery from "./pages/MetroGallery";
import SportGallery from "./pages/SportGallery";
import HistoricalGallery from "./pages/HistoricalGallery";
import TourismGallery from "./pages/TourismGallery";
import Booking from "./components/Booking/Booking";
import Payment from "./components/Payment/Payment";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/Photo_Gallery/cities_gallery" element={<CitiesGallery />} />
      <Route path="/Photo_Gallery/green_gallery" element={<GreenGallery />} />
      <Route
        path="/Photo_Gallery/culture_gallery"
        element={<CultureGallery />}
      />
      <Route
        path="/Photo_Gallery/architecture_gallery"
        element={<ArchitectureGallery />}
      />
      <Route path="/Photo_Gallery/people_gallery" element={<PeopleGallery />} />
      <Route path="/Photo_Gallery/food_gallery" element={<FoodGallery />} />
      <Route path="/Photo_Gallery/metro_gallery" element={<MetroGallery />} />
      <Route path="/Photo_Gallery/art_gallery" element={<ArtGallery />} />
      <Route path="/Photo_Gallery/sport_gallery" element={<SportGallery />} />
      <Route
        path="/Photo_Gallery/historical_gallery"
        element={<HistoricalGallery />}
      />
      <Route
        path="/Photo_Gallery/tourism_gallery"
        element={<TourismGallery />}
      />
      <Route path="/booking" element={<Booking />} />
      <Route path="/payment" element={<Payment />} />
    </Routes>
  );
}

export default App;
