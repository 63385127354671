import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import style from "./customizedDialog.module.scss";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { CgClose } from "react-icons/cg";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({ title, content }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        {t("readMore")}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"xl"}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CgClose />
        </IconButton>
        <DialogContent dividers>
          <div
            className={style.modal}
            style={{ padding: "1rem" }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </DialogContent>
        <DialogActions>
          <Link to={"/booking"}>
            <Button
              sx={{ color: "#ffc300", fontWeight: "700" }}
              autoFocus
              onClick={handleClose}
            >
              {t("booknow")}
            </Button>
          </Link>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
